<template>
    <card>
      <body-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('globalTrans.edit') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col sm="12">
                <b-tabs content-class="mt-0" fill>
                  <b-tab :title="$t('license_management.application')" active>
                    <div v-if="loading">
                      <Loading />
                    </div>
                    <b-overlay v-else>
                      <div class="p-3">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                            <b-form @submit.prevent="handleSubmit(finalSave)" @reset.prevent="reset">
                              <b-row>
                                <b-col sm="12">
                                  <div class="text-right">
                                  </div>
                                  <div>
                                    <!-- Application view start -->
                                    <div class="application-form-wrapper application-form-view-wrapper mt-5">
                                      <div class="application-itmes">
                                        <div class="group-form-card">
                                          <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.general_information')" label-size="md"
                                            label-class="font-weight-bold pt-0" class="form-view-item">
                                            <b-row>
                                              <b-col sm="6">
                                                <b-form-group :label="$t('national_award.circular')" label-for="circular">
                                                  <p class="input-field">{{ getCircularName(appDetail.circular_id) }}</p>
                                                </b-form-group>
                                              </b-col>
                                              <b-col sm="6">
                                                <b-form-group :label="$t('national_award.category')" label-for="category">
                                                  <p class="input-field">{{ getCategoryName(appDetail.category_id) }}</p>
                                                </b-form-group>
                                              </b-col>
                                            </b-row>
                                            <b-row>
                                              <b-col sm="6">
                                                <b-form-group :label="$t('teaGardenPanel.applicant_name')" label-for="ApplicantNameEn">
                                                  <p class="input-field"> {{ currentLocale === 'en' ? appDetail.applicant_name_en : appDetail.applicant_name_bn }} </p>
                                                </b-form-group>
                                              </b-col>
                                              <b-col sm="6">
                                                <b-form-group :label="$t('globalTrans.mobile')" label-for="ApplicantNameEn">
                                                  <p class="input-field">{{ appDetail.applicant_mobile | mobileNumber }}</p>
                                                </b-form-group>
                                              </b-col>
                                            </b-row>
                                            <b-row>
                                              <b-col sm="6">
                                                <b-form-group :label="$t('globalTrans.email')" label-for="ApplicantNameEn">
                                                  <p class="input-field">{{ appDetail.applicant_email }}</p>
                                                </b-form-group>
                                              </b-col>
                                              <b-col sm="6">
                                                <b-form-group :label="$t('globalTrans.designation')" label-for="DesignationEn">
                                                  <p class="input-field">{{ currentLocale === 'en' ? appDetail.applicant_designation_en : appDetail.applicant_designation_bn }}</p>
                                                </b-form-group>
                                              </b-col>
                                            </b-row>
                                            <b-row>
                                              <b-col sm="6">
                                                <b-form-group :label="$t('teaGardenPanel.applicant_company')" label-for="DesignationEn">
                                                  <p class="input-field">{{ currentLocale === 'en' ? appDetail.company_name_en : appDetail.company_name_bn }}</p>
                                                </b-form-group>
                                              </b-col>
                                              <b-col sm="6">
                                                <b-form-group :label="$t('portal.org_type')" label-for="DesignationEn">
                                                  <p class="input-field">{{ getOrgTypeName(appDetail.org_type) }}</p>
                                                </b-form-group>
                                              </b-col>
                                            </b-row>
                                          </b-form-group>
                                        </div>
                                        <div class="group-form-card">
                                          <b-form-group label-cols-lg="3" :label="$t('national_award.application_details')" label-size="md"
                                            label-class="font-weight-bold pt-0" class="form-view-item">
                                            <b-row>
                                              <!-- category 1 -->
                                              <category-1
                                                  :detail="appDetail.detail"
                                                  :categoryDetails="categoryDetails"
                                                  v-if="appDetail.category_id === 1"
                                              />
                                              <!-- category 2 -->
                                              <category-2
                                              :detail="appDetail.detail"
                                              :categoryDetails="categoryDetails"
                                              v-if="appDetail.category_id === 2"
                                              />
                                              <!-- category 3 -->
                                              <category-3
                                              :detail="appDetail.detail"
                                              :categoryDetails="categoryDetails"
                                              v-if="appDetail.category_id === 3"
                                              />
                                              <!-- category 4 -->
                                              <category-4
                                              :detail="appDetail.detail"
                                              :categoryDetails="categoryDetails"
                                              v-if="appDetail.category_id === 4"
                                              />
                                              <!-- category 5 -->
                                              <category-5
                                              :detail="appDetail.detail"
                                              :categoryDetails="categoryDetails"
                                              v-if="appDetail.category_id === 6"
                                              />
                                              <!-- category 6 -->
                                              <category-6
                                              :detail="appDetail.detail"
                                              :categoryDetails="categoryDetails"
                                              v-if="appDetail.category_id === 5"
                                              />
                                              <!-- category 7 -->
                                              <category-7
                                              :detail="appDetail.detail"
                                              :categoryDetails="categoryDetails"
                                              v-if="appDetail.category_id === 7"
                                              />
                                              <!-- category 8 -->
                                              <category-8
                                              :detail="appDetail.detail"
                                              :categoryDetails="categoryDetails"
                                              :workerType="workerType"
                                              v-if="appDetail.category_id === 8"
                                              />
                                            </b-row>
                                          </b-form-group>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row class="text-right mb-2">
                                    <b-col>
                                        <b-button @click="back" class="btn-sm"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
                                        <b-button type="submit" variant="success" class="mr-2 btn-sm ml-3"><i class="ri-save-line"></i> {{ $t('globalTrans.update')}}</b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                          </ValidationObserver>
                      </div>
                    </b-overlay>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </template>
      </body-card>
    </card>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { editApplication } from '../../../api/routes'
import Loading from './Details/loading/Details.vue'
import Category1 from '@/modules/portal/pages/tea-garden-btb-award-circular-notice/category-based-components/Category1.vue'
import Category2 from '@/modules/portal/pages/tea-garden-btb-award-circular-notice/category-based-components/Category2.vue'
import Category3 from '@/modules/portal/pages/tea-garden-btb-award-circular-notice/category-based-components/Category3.vue'
import Category4 from '@/modules/portal/pages/tea-garden-btb-award-circular-notice/category-based-components/Category4.vue'
import Category5 from '@/modules/portal/pages/tea-garden-btb-award-circular-notice/category-based-components/Category5.vue'
import Category6 from '@/modules/portal/pages/tea-garden-btb-award-circular-notice/category-based-components/Category6.vue'
import Category7 from '@/modules/portal/pages/tea-garden-btb-award-circular-notice/category-based-components/Category7.vue'
import Category8 from '@/modules/portal/pages/tea-garden-btb-award-circular-notice/category-based-components/Category8.vue'
export default {
  name: 'Details',
  components: {
    Loading, Category1, Category2, Category3, Category4, Category5, Category6, Category7, Category8
  },
  data () {
    return {
      loading: false,
      forwarLoad: false,
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      appDetail: {},
      forwardsShow: false,
      recommendationShow: false,
      users: [],
      categoryList: [],
      categoryDetails: []
    }
  },
  created () {
    this.appDetail = this.getAppDetail()
  },
  computed: {
    isExternalUser () {
      let isExternal = false
      if (this.$store.state.Auth.authUser.user_type === 2) {
        isExternal = true
      }
      return isExternal
    },
    currentLocale () {
      return this.$i18n.locale
    },
    appId () {
      return this.$route.params.id
    },
    stepList () {
      const setp = [
        { value: 1, text: this.$t('national_award.submitted') },
        { value: 2, text: this.$t('national_award.application_received') }
      ]
      return setp
    },
    orgType () {
      return [
        {
            value: 1,
            text: this.$i18n.locale === 'en' ? 'Tea Garden' : 'চা বাগান'
        },
        {
            value: 2,
            text: this.$i18n.locale === 'en' ? 'Tea Factory' : 'চা কারখানা'
        },
        {
            value: 3,
            text: this.$i18n.locale === 'en' ? 'Export Company' : 'রপ্তানি কোম্পানি'
        },
        {
            value: 4,
            text: this.$i18n.locale === 'en' ? 'Person' : 'ব্যক্তি'
        },
        {
            value: 5,
            text: this.$i18n.locale === 'en' ? 'Blender' : 'ব্লেন্ডার'
        },
        {
            value: 6,
            text: this.$i18n.locale === 'en' ? 'Small Farmer' : 'ক্ষুদ্র চা চাষি'
        }
      ]
    },
    yesNoList () {
      return [
          {
              value: 1,
              text: this.$i18n.locale === 'en' ? 'Yes' : 'হ্যাঁ'
          },
          {
              value: 0,
              text: this.$i18n.locale === 'en' ? 'No' : 'না'
          }
      ]
    },
    workerType () {
        return [
            {
                value: 1,
                text: this.$i18n.locale === 'en' ? 'Registered' : 'নিবন্ধিত'
            },
            {
                value: 2,
                text: this.$i18n.locale === 'en' ? 'Unregistered' : 'অনিবন্ধিত'
            }
        ]
    }
  },
  methods: {
    isImage (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
    },
    back () {
      this.$router.go(-1)
    },
    async getAppDetail () {
        this.loading = true
        const result = await RestApi.getData(teaGardenServiceBaseUrl, '/btb/national-tea-award/application/show' + '/' + this.appId)
        if (result.success) {
          this.appDetail = result.data.app

          const processArray = (arr) => {
            return arr.map(item => {
                const textKey = `text_${this.$i18n.locale}`
                return { ...item, text: item[textKey] }
            }) || []
          }
          this.categoryList = processArray(result.data.categories)

          this.categoryDetails = this.categoryList.find(cat => cat.value === this.appDetail.category_id).details
        }
        this.loading = false
    },
    getOrgTypeName (id) {
      const data = this.orgType.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return data.text
      } else {
        return ''
      }
    },
    getYesNoName (id) {
      const data = this.yesNoList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return data.text
      } else {
        return ''
      }
    },
    getWorkerTypeName (id) {
      const data = this.workerType.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return data.text
      } else {
        return ''
      }
    },
    getCircularName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.teaAwardCircularList.find(item => item.value === id)
      if (obj) {
        return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
      }
      return ''
    },
    getCategoryDetails (id) {
        return id
    },
    getCategoryName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.teaAwardCategoryList.find(item => item.value === id)
      if (obj) {
        return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
      }
      return ''
    },
    finalSave () {
        this.$swal({
            title: this.$t('national_award.update_msg'),
            showCancelButton: true,
            confirmButtonText: this.$t('globalTrans.yes'),
            cancelButtonText: this.$t('globalTrans.no'),
            focusConfirm: false
        }).then((result) => {
            if (result.isConfirmed) {
                this.submit()
            }
        })
    },
    async submit () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        this.appDetail.status = 2
        result = await RestApi.postData(teaGardenServiceBaseUrl, `${editApplication}/${this.appId}`, this.appDetail)

        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
            this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
            this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
            })
            this.$router.go(-1)
        } else {
            if (result.is_exist) {
                this.$toast.error({
                    title: 'Error',
                    message: this.$i18n.locale === 'bn' ? result.msg_en : result.msg_bn,
                    color: '#D6E09B'
                })
            }
            this.$refs.form.setErrors(result.errors)
        }
    }
  }
}
</script>
<style scoped>
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }

.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    }
    .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    @media (max-width: 768px) {
        font-size: 12px;
    }
    }

    .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
    }

    .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
    }

    .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    color: white;
    }

    .stepper-item.active {
    font-weight: bold;
    }

    .stepper-item.completed .step-counter {
    background-color: #4bb543;
    color:white;
    }

    .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
    }

    .stepper-item:first-child::before {
    content: none;
    }
    .stepper-item:last-child::after {
    content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
.tagTwo {
  position: relative;
  display: inline-block;
  border-radius: 6px;
  clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
  background: hsl(250deg, 100%, 40%);
  padding: 5px 25px;
  font-weight: 600;
  font-size: 12px;
  color: #FFF;
  transition: clip-path 500ms;
}
.tagTwo:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: hsl(252deg, 100%, 60%);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 0;
  transition: transform 500ms;
}
</style>
